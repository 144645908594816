<div class="page-user">
    <div class="col-md-12 m-2">
        <label>Resultat</label>
        <input class="form-control" type="text" [(ngModel)]="mot" />
    </div>
    <div class="col-md-12 m-2">
        <label>Coef</label>
        <input class="form-control" type="text" [(ngModel)]="tcoef" />
    </div>
    <div class="col-md-12 m-2">
        <label>Note</label>
        <input class="form-control" type="text" [(ngModel)]="tnote" />
    </div>
    <div class="col-md-12 m-2">
        <label>Cours</label>
        <input class="form-control" type="text" [(ngModel)]="tmat" />
    </div>
    <div class="col-md-12 m-2">
        <label>Message</label>
        <input class="form-control" type="text" [(ngModel)]="mot2" />
    </div>
    <div class="col-md-12 m-2">
        <label>Marge</label>
        <input class="form-control" type="number" min="1" [(ngModel)]="umg" (keyup)="setMarge(umg)" />
    </div>
    <div class=" row">
        <div class="form-group col-md-12 row  ">
            <div class="col-md-4" style="float:left;">
                <label for="recipient-name-1" class="col-form-label">Rechercher</label>
            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" autocomplete="off" (keyup)="onKey($event)">
            </div>
        </div>
    </div>
    <div class="table-responsive">

        <table id="zero_configuration_table" class="display table table-striped table-bordered">
            <thead>
                <tr>
                    <th>Nom et prénom</th>
                    <th>Marge</th>
                </tr>
            </thead>
            <tbody>
                <tr (click)="changePage(o.id,IDF,i)" *ngFor="let o of STUDS; let i = index" [style.background-color]="setBG(o.id)">
                    <td [style.color]="setTC(o)"> <a pageScroll href="#USER-{{o.id}}"> {{o.nom}} {{o.pnom}}
                        ({{o.code_student}})</a> </td>
                    <td> <input min="1" class="form-control" type="number" [(ngModel)]="mg[o.id]" /></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="page-print">
    <label *ngIf="pageH[0]">PAGE HEIGHT : {{ pageH[0] }} PX</label>
    <div class="form-group">
        <label>Décision </label>
        <input type="checkbox" [(ngModel)]="dec" class="form-control" />
    </div>
    <div class="form-group">
        <label>Parent </label>
        <input type="checkbox" [(ngModel)]="parent" class="form-control" />
    </div>
    <button *ngIf="rprint" class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint>print</button>
    <button class="btn btn-sm btn-primary m-1" (click)="edit=!edit"> <i *ngIf="edit"> Fermer note </i> <i *ngIf="!edit">
        Ouvrir note </i> </button>
    <button class="btn btn-sm btn-success m-1 row" (click)="getMoyGen()"> Mise a jour de la moyenne de la classe </button>

</div>

<div class="book" id="print-section">
    <div id="USER-{{o.user?.id}}" class="page" *ngFor="let o of ALL;let i = index">
        <app-entete [etab]="etab"></app-entete>
        <div class="col-md-12 ">
            <div class="identity">
                <p class="line-text"> <b class="reponse"> Bulletin {{o.user?.promofrag.code.split("(")[0].split("-")[3]}}
                  </b></p>
                <p class="line-text-a "> <b class="reponse">Nom :</b><span class="reponse">{{o?.user?.nom}} </span> </p>
                <p class="line-text-b"> <b class="reponse">{{o?.user?.code_student}}</b> </p>
                <p class="line-text-a "> <b class="reponse">Prénom :</b><span class="reponse"> {{o?.user?.pnom}}</span> </p>
                <p class="line-text-b"> <b class="reponse">{{o.user?.promo_name.split("(")[0].split("-")[1]}}-{{o.user?.promo_name.split("(")[0].split("-")[2]}}</b>
                </p>
                <p class="line-text-left"> <b class="reponse">Classe :</b><span class="reponse">{{o?.user?.promo_name.split("(")[0].split("-")[0]}}</span> </p>
                <p class="line-text-left"> <b class="reponse"> Place: </b> <span class="reponse">{{(i+1)}} <i *ngIf="i==0" class="i-Star"></i></span></p>
            </div>
            <table class="display my-table  table-bordered " style="width:100%">
                <thead>
                    <tr>
                        <th>{{tmat}}</th>
                        <th>{{tcoef}}</th>
                        <th>{{tnote}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let r of o.user.results; let j = index">
                        <td>{{ r.frag_cours.name}} </td>
                        <td>
                            {{ r.note_total * r.coef }}
                        </td>
                        <td>
                            <span *ngIf="!edit" style="font-size:12px;">{{ r.note*r.coef }}</span>
                            <span *ngIf="edit">
 <label class="text-success m-2 row" *ngIf="msg[r.id]">{{msg[r.id]}}</label>
 <label class="text-danger m-2 row" *ngIf="err[r.id]">{{err[r.id]}}</label>
<input class="note-input" [(ngModel)]="r.note" class="form-control" (blur)="editNote(r)" />
                            </span>

                        </td>
                    </tr>
                    <tr>
                        <td><b>Total</b></td>
                        <td>{{ o.ctotal }}</td>
                        <td>{{ o.ntotal }}</td>
                    </tr>
                    <tr>
                        <td>Moyenne de l'élève</td>
                        <td>{{promo?.moy_total}}</td>
                        <td> <b>{{ o.moy }}
                          </b></td>

                    </tr>
                    <tr>
                        <td>Moyenne de la classe</td>
                        <td>{{promo?.moy_total}}</td>
                        <td> <b>{{ ( moy * promo?.moy_total).toFixed(2) }} </b></td>
                    </tr>
                </tbody>
            </table>
            <table class="display my-table  table-bordered " style="width:100%" *ngIf="etab.app">
                <thead>
                    <tr>
                        <th style="font-weight:none;">Application</th>

                        <th style="font-weight:none;">{{o.user?.note_1}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Démérites</td>
                        <td>{{o.user.note_2}}</td>
                    </tr>
                    <tr>
                        <td>Absences</td>
                        <td>{{o.user.absence}}</td>
                    </tr>
                    <tr>
                        <td>Retard</td>
                        <td>{{o.user.retard}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-12" attr.style="margin-top:{{mg[o.user.id]/5+'px'}}; margin-bottom:{{mg[o.user.id]/3+'px'}};">
            <div class="mention" *ngIf="dec">
                <p class="text-left "> <b class="reponse">{{mot}}:</b>
                    <i class="reponse text-success" *ngIf="o.moy>=promo?.moy_accept &&  o.moy<promo?.moy_exc ">
                      Réussi</i>
                    <i class="reponse text-success" *ngIf="o.moy>=promo?.moy_exc ">
                      Excellent</i>
                    <i class="reponse text-danger" *ngIf="o.moy<promo?.moy_accept">
                      Echec</i>
                </p>
            </div>
            <div class="mention" *ngIf="results[0]?.parcours_frag?.mention">
                <p class="text-left "> <b class="reponse">{{mot2}}:</b><i class="reponse"> {{o.user?.mention}}</i> </p>
            </div>
            <div class="mention" *ngIf="etab.tuts" attr.style="margin-top:{{(mg[o.user.id]/5)*4+'px'}};">
                <div class="ml-1 mt-2 signature prof">
                    Signature du titulaire
                </div>
                <div class="mt-2 signature parent mr-4" *ngIf="parent">
                    Signature des parents
                </div>
            </div>
            <div class="mention" *ngIf="!etab.tuts" attr.style="margin-top:{{(mg[o.user.id]/5)*4+'px'}};">
                <div class="ml-1 mt-2 signature prof">
                    Signature du directeur
                </div>
                <div class="mt-2 signature parent mr-4" *ngIf="parent">
                    Signature des parents
                </div>
            </div>
            <div class="mt-4 signature direct" *ngIf="etab.tuts">
                Signature du directeur
            </div>
        </div>
    </div>
</div>
