<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="col-md-12 mb-2">
            <h3 class="mb-1  text-center"> Nouvelle transaction </h3>
            <a routerLink="/app/paiement" class="mb-3 text-danger text-center"> Retourner </a>
        </div>

        <div class="row">
            <!-- end of row-->
            <div class="col-md-6 " style="margin-left:25%;">
                <div class="card card-profile-1 mb-4">
                    <div class="card-body">
                        <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                            {{response.message}}
                            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="recipient-name-1" class="col-form-label">Type transaction</label>
                                <select [(ngModel)]="pass.tcs" class="form-control">
                                  <option value=""></option>
                                  <option value="1">Encaissement</option>
                                  <option value="2">Decaissement</option>
                                </select>
                                <label class="text-success m-1 text-center" *ngIf="pass.tcs==1">Vous êtes en train de faire un encaissement</label>
                                <label class="text-warning m-1 text-center" *ngIf="pass.tcs==2">Vous êtes en train de faire un
     decaissement</label>
                                <label class="text-danger m-1 text-center" *ngIf="pass.tcs==0">Vous devez choissir le type de
     transaction que vous voulez faire.</label>
                            </div>
                            <div class="form-group col-md-12">
                                <label for="recipient-name-1" class="col-form-label">Votre Code </label>
                                <input type="text" autocomplete="off" [(ngModel)]="pass.code" id="pass1" class="form-control">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="recipient-name-1" class="col-form-label">Votre Pin</label>
                                <input type="password" autocomplete="off" [(ngModel)]="pass.pin" id="nom" class="form-control">
                            </div>
                            <div class="form-group col-md-12">
                                <label for="recipient-name-1" class="col-form-label">Le montant</label>
                                <input type="number" autocomplete="off" [(ngModel)]="pass.montant" id="nom" class="form-control">
                            </div>

                            <div class="form-group col-md-12">
                                <label for="recipient-name-1" class="col-form-label">Pourquoi vous voulez faire cet encaissement ?</label>
                                <input type="text" autocomplete="off" [(ngModel)]="pass.raison" id="nom" class="form-control">
                            </div>
                            <div class="form-group col-md-12">
                                <button (click)="setEnc()" style="float:right;" [disabled]="loading || !pass?.code || !pass?.pin || !pass?.montant || !pass?.tcs " class="btn btn-primary  btn-rounded mt-3">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Valider
                </button>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>




        <!-- end of row-->
        <!-- Footer Start -->

        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->
