<div class="auth-layout-wrap" [style.background-image]="setBG()">
    <div class="auth-content">
        <div class="card o-hidden">
            <div class="row">
                <div class="col-md-8" style="margin-left: auto; margin-right:auto;">
                    <div class="p-4">
                        <div class="auth-logo text-center mb-4">
                            <!-- <img src="assets/images/logo.png" alt=""> -->
                        </div>
                        <h3 class="mb-3  text-center">{{etab?.name}}</h3>
                        <div class="separator-breadcrumb border-top"></div>
                        <h1 class="mb-3 text-16 text-center">Ouvrir une session</h1>
                        <div *ngIf="response.active" class="alert alert-{{response.state}} mt-3 mb-0">{{response.message}}</div>
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                            <div class="form-group">
                                <label for="username">Email ou Nom d'utilisateur ou Code</label>
                                <input formControlName="username" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" class="form-control form-control-rounded" type="email">
                                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                                    <div *ngIf="f.username.errors.required">Vous devez ajouter un email</div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="username">Mot de passe</label>
                                <input formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control form-control-rounded" type="password">
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Vous devez ajouter un mot de passe</div>
                                </div>
                            </div>

                            <button [disabled]="loading" class="btn btn-primary btn-block btn-rounded mt-2">
                               <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                               se connecter
                             </button>
                        </form>
                        <div class="mt-3 text-center">
                            <a routerLink="/app/fpass" class="text-muted"><u>Mot de passe oublié? </u></a>,
                            <a routerLink="/app/siteweb" class="text-muted"> <u>Portal</u> </a>,
                            <a routerLink="/app/setEtab" class="text-muted"> <u>Changer d'etablissement</u> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>