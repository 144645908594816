<div class="page-user">
    <div class=" row">
        <div class="form-group col-md-12 row  ">
            <div class="col-md-4" style="float:left;">
                <label for="recipient-name-1" class="col-form-label">Rechercher</label>
            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" autocomplete="off" (keyup)="onKey($event)">
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table id="zero_configuration_table" class="display table table-striped table-bordered">
            <thead>
                <tr>

                    <th>Nom et prénom</th>
                    <th>Impressions</th>
                </tr>
            </thead>
            <tbody>
                <tr (click)="changePage(o,i)" *ngFor="let o of STUDS; let i = index" [style.background-color]="setBG(o.id)">
                    <td [style.color]="setTC(o)">{{o.nom}} {{o.pnom}} ({{o.code_student}})</td>
                    <td>{{o?.print}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="page-print">
    <div class="row col-md-12">
        <div class="form-group col-md-12 ">
            <label for="recipient-name-1" class="col-form-label">Année academique</label>
            <select [(ngModel)]="cyear" class="form-control" (change)="getYear(cyear)">
                         <option [ngValue]="a" *ngFor="let a of afs">  {{a?.date_debut?.split("T")[0].split("-")[0]}} - {{a?.date_fin?.split("T")[0].split("-")[0]}}</option>
                       </select>
        </div>
        <div class="form-group col-md-12" *ngIf="promos">
            <label for="recipient-name-1" class="col-form-label">Promotion</label>
            <select [(ngModel)]="cpromo" class="form-control" (change)="getCPromo(cpromo)">
                         <option [ngValue]="a" *ngFor="let a of promos"> {{a.code}}</option>
                       </select>
        </div>
    </div>
    <div class="form-group col-md-12">
        <button class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint>print</button>
    </div>

</div>
<div class="book" id="print-section">
    <div class="page">
        <app-entete [etab]="etab"></app-entete>
        <div class="col-md-12 ">
            <div class="identity">
                <p class="line-text"> <b class="reponse">Relevée de note  </b></p>
                <p class="line-text-a "> <b class="reponse">Nom et Prénom :</b><span class="reponse">{{cuser?.nom}} {{cuser?.pnom}}</span> </p>

                <p class="line-text-b"> <b class="reponse">{{cuser?.code_student}}</b> </p>
                <p class="line-text-a "> <b class="reponse">Classe :</b><span class="reponse">{{cpromo?.code.split("(")[0].split("-")[0]}}</span> </p>
                <p class="line-text-b"> <b class="reponse">{{cpromo?.code.split("(")[0].split("-")[1]}}-{{cpromo?.code.split("(")[0].split("-")[2]}}</b>
                </p>
            </div>
            <div class="col-md-12">
                <table class="display my-table table-bordered " style="width:100%">
                    <thead *ngIf="etab">
                        <tr>
                            <th> Matières</th>
                            <th class="control">Coéf</th>
                            <th class="control" *ngFor="let p of nper;"> {{setText(p)}}</th>
                            <th class="control">Moy. Ann.</th>
                            <th class="control" *ngIf="total.extra>0 && etab.reprise==1">Ex. Extra </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="etab">
                        <tr *ngFor="let o of user?.results; let i = index">
                            <td class="control">{{ o.frag_cours}}</td>
                            <td>
                                {{ o.coef * o.note_total }}
                            </td>
                            <td *ngFor="let p of nper;">
                                {{ (o[p]) ? o.coef * o[p] : '0' }}
                            </td>
                            <td>
                                {{ o.coef * o.moy_ann }}
                            </td>
                            <td *ngIf="total.extra>0 && etab.reprise==1">
                                {{ o.coef * o.extra }}
                            </td>
                        </tr>
                        <tr>
                            <td><b>Total</b></td>
                            <td>{{ user?.total?.coef }}</td>
                            <td *ngFor="let p of nper;">{{ (user?.total[p]) ? user?.total[p] :'0' }}</td>
                            <td>{{ user?.total?.moy.toFixed(2) }}</td>
                            <td *ngIf="user?.total.extra>0 &&  etab.reprise==1">{{ total?.extra }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-12" class="style:100px;">
            <div class="mention">
                <div class="ml-1 mt-2 signature  prof">
                    Date
                </div>
                <div class="mt-2 signature parent mr-4">
                    Signature du responsable
                </div>
            </div>
        </div>
    </div>
</div>