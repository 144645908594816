<div class="main-header">
    <div class="logo">
        <img *ngIf="logo" [src]="logo" alt="">
        <img *ngIf="!logo" src="assets/logo.jpeg" alt="">
    </div>

    <div class="menu-toggle">
        <div></div>
        <div></div>
        <div></div>
    </div>

    <div class="d-flex align-items-center">
        <!-- / Mega menu -->
    </div>

    <div style="margin: auto"></div>

    <div class="header-part-right">
        <a class="btn btn-primary text-light" (click)="goToPOC()">Cours en ligne</a>
        <!-- Full screen toggle -->
        <i class="i-Full-Screen header-icon d-none d-sm-inline-block" data-fullscreen></i>
        <i class="i-Refresh text-muted header-icon" (click)="reload()"></i>
        <!-- Notificaiton -->
        <div class="dropdown">
            <div class="badge-top-container" id="dropdownNotification" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="badge badge-primary">{{nots.length}}</span>
                <i class="i-Bell text-muted header-icon"></i>
            </div>
            <!-- Notification dropdown -->
            <div class="dropdown-menu dropdown-menu-right notification-dropdown" aria-labelledby="dropdownNotification" data-perfect-scrollbar data-suppress-scroll-x="true">
                <div routerLink="/app/not/{{item.id}}" class="dropdown-item d-flex" *ngFor="let item of nots">
                    <div class="notification-icon">
                        <i class="i-Speach-Bubble-6 text-primary mr-1"></i>
                    </div>
                    <div class="notification-details flex-grow-1">
                        <p class="m-0 d-flex align-items-center">
                            <span>{{item.titre}}</span>
                            <span class="badge badge-pill badge-primary ml-1 mr-1">Nouveau</span>
                            <span class="flex-grow-1"></span>
                            <span class="text-small text-muted ml-auto">{{item.created_at.split("T")[0]}}</span>
                        </p>
                        <p class="text-small text-muted m-0">{{item.msg}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Notificaiton End -->
        <!-- User avatar dropdown -->
        <div class="dropdown">
            <div class="user colalign-self-end">
                <!--img *ngIf="user?.avatar" [src]="getImg(user?.avatar)" id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <img *ngIf="!user?.avatar" [src]="getImg('default.png')" id="userDropdown" alt="" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">-->
                <i class="i-Business-Man header-icon" id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                    <div class="dropdown-header">
                        <i class="i-Lock-User mr-1"></i> {{user.lastName}} {{user.firstName}} ({{user.role.name}})
                    </div>
                    <div class="dropdown-header" *ngIf="user.role.name=='MASTER' || user.role.name=='ACCOUNTING' || user.role.name=='ADMIN'  ">
                        <i class="i-Money1  mr-1"></i> {{user.sold}} G
                    </div>
                    <a class="dropdown-item" routerLink="/app/profil">Votre profil</a>

                    <a class="dropdown-item" routerLink="/app/cpass">Confidentialité</a>
                    <a class="dropdown-item" (click)="logout()">Déconnecter</a>
                </div>
            </div>
        </div>
    </div>

</div>
