import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-paie-page',
  templateUrl: './paie-page.component.html',
  styleUrls: ['./paie-page.component.css']
})
export class PaiePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
