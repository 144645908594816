import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pay-admis',
  templateUrl: './pay-admis.component.html',
  styleUrls: ['./pay-admis.component.css']
})
export class PayAdmisComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
