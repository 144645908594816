<div class="flex-grow-1"></div>
<div class="app-footer">
    <app-internet></app-internet>
    <div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">

        <span class="flex-grow-1"></span>
        <div class="d-flex align-items-center">
            <img class="logo" src="assets/logo.jpeg" alt=" ">
            <div>
                <p class="m-0">&copy; 2020 PLEDIKA</p>
                <p class="m-0">All rights reserved</p>
            </div>
        </div>
    </div>
</div>