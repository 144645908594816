<div class="page-user">
    <div class="col-md-12 m-2">
        <label>Resultat</label>
        <input class="form-control" type="text" [(ngModel)]="mot" />
    </div>
    <div class="col-md-12 m-2">
        <label>Message</label>
        <input class="form-control" type="text" [(ngModel)]="mot2" />
    </div>
    <div class="col-md-12 m-2">
        <label>Marge</label>
        <input class="form-control" type="number" min="1" [(ngModel)]="marge" />
    </div>
    <div class=" row">

        <div class="form-group col-md-12 row  ">
            <div class="col-md-4" style="float:left;">
                <label for="recipient-name-1" class="col-form-label">Rechercher</label>
            </div>
            <div class="col-md-8">
                <input type="text" class="form-control" autocomplete="off" (keyup)="onKey($event)">
            </div>
        </div>
    </div>
    <div class="table-responsive">
        <table id="zero_configuration_table" class="display table table-striped table-bordered">
            <thead>
                <tr>

                    <th>Nom et prénom</th>
                    <th>Impressions</th>
                </tr>
            </thead>
            <tbody>
                <tr (click)="changePage(o.id,IDF,i)" *ngFor="let o of STUDS; let i = index" [style.background-color]="setBG(o.id)">
                    <td [style.color]="setTC(o)">{{o.nom}} {{o.pnom}} ({{o.code_student}})</td>
                    <td>{{o?.print}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="page-print">
    <button class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint>print</button>
</div>
<div class="page" id="print-section">
    <app-entete [etab]="etab"></app-entete>
    <div class="col-md-12 ">
        <div class="identity">
            <p class="line-text"> <b class="reponse">Bulletin {{pf?.code.split("(")[0].split("-")[3]}} </b></p>
            <p class="line-text-a "> <b class="reponse">Nom et Prénom :</b><span class="reponse">
               {{results[0]?.parcours_frag?.nom}} {{results[0]?.parcours_frag?.pnom}}</span> </p>
            <p class="line-text-b"> <b class="reponse">{{results[0]?.code_student}}</b> </p>
            <p class="line-text-a "> <b class="reponse">Classe :</b><span class="reponse">{{pf?.code.split("(")[0].split("-")[0]}}</span> </p>
            <p class="line-text-b"> <b class="reponse">{{pf?.code.split("(")[0].split("-")[1]}}-{{pf?.code.split("(")[0].split("-")[2]}}</b>
            </p>
        </div>
        <table class="display my-table  table-bordered " style="width:100%">
            <thead>
                <tr>
                    <th>Matieres</th>
                    <th>Coéfficient</th>
                    <th>Note</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let o of results; let i = index">
                    <td>{{ o.frag_cours.name}} </td>
                    <td>
                        {{ o.note_total * o.coef }}
                    </td>
                    <td>{{ o.note*o.coef }}</td>
                </tr>
                <tr>
                    <td><b>Total</b></td>
                    <td>{{ total?.coef }}</td>
                    <td>{{ total?.note }}</td>
                </tr>
                <tr>
                    <td>Moyenne de l'élève</td>
                    <td>{{promo?.moy_total}}</td>
                    <td> <b>{{((total.note/total.coef) * promo?.moy_total).toFixed(2)}} </b></td>
                </tr>
                <tr>
                    <td>Moyenne de la classe</td>
                    <td>{{promo?.moy_total}}</td>
                    <td> <b>{{ ( moy * promo?.moy_total).toFixed(2) }} </b></td>
                </tr>
            </tbody>
        </table>
        <table class="display my-table  table-bordered " style="width:100%" *ngIf="etab?.app">
            <thead>
                <tr>
                    <th style="font-weight:none;">Application</th>

                    <th style="font-weight:none;">{{results[0]?.parcours_frag.note_1}}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Démérites</td>
                    <td>{{results[0]?.parcours_frag.note_2}}</td>
                </tr>
                <tr>
                    <td>Absences</td>
                    <td>{{results[0]?.parcours_frag.absence}}</td>
                </tr>
                <tr>
                    <td>Retard</td>
                    <td>{{results[0]?.parcours_frag.retard}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-md-12">
        <div class="mention">
            <p class="text-left "> <b class="reponse">{{mot}}:</b>
                <i class="reponse text-success" *ngIf="((total.note/total.coef) * promo?.moy_total).toFixed(2)>=promo?.moy_accept  && ((total.note/total.coef) * promo?.moy_total).toFixed(2)<promo?.moy_exc">Réussi</i>
                <i class="reponse text-success" *ngIf="((total.note/total.coef) * promo?.moy_total).toFixed(2)>=promo?.moy_exc"> Excellent</i>
                <i class="reponse text-danger" *ngIf="((total.note/total.coef) * promo?.moy_total).toFixed(2)<promo?.moy_accept"> Echec</i>
            </p>
        </div>
        <div class="mention" *ngIf="results[0]?.parcours_frag?.mention">
            <p class="text-left "> <b class="reponse">{{mot2}}:</b><i class="reponse"> {{results[0]?.parcours_frag?.mention}}</i> </p>
        </div>
        <div class="marger" attr.style="margin-top:{{marge+'px'}};"> </div>
        <div class="mention" *ngIf="etab?.tuts">
            <div class="ml-1 mt-2 signature prof">
                Signature du titulaire
            </div>
            <div class="mt-2 signature parent mr-4">
                Signature des parents
            </div>
        </div>
        <div class="mention" *ngIf="!etab?.tuts">
            <div class="ml-1 mt-2 signature prof">
                Signature du directeur
            </div>
            <div class="mt-2 signature parent mr-4">
                Signature des parents
            </div>
        </div>
        <div class="mt-4 signature direct" *ngIf="etab?.tuts">
            Signature du directeur
        </div>
    </div>
</div>