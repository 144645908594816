import { Ville } from './Ville';

export class User {

    id;
    code;
    firstName;
    lastName;
    username;
    password;
    avatar;
    etat_civil ;
    sexe ;
    statut ;
    reference;
    nom_conjoint;
    phone ;
    hphone ;
    adresse;
    arefaire;
    sold;
    hsold;
    prev_class;
    next_class;
    current_class;
    nationalite ;
    autre_nom ;
    salairy;
    token;
    date_de_naiss;
    last_year ;
    last_moyen ;
    last_etab;
    nom_ass ;
    phone_ass ;
    adresse_ass;
    email_ass;
    valide_doc;
    role;
    parent;
    courses;
    adresses;
    phones;
    maladies;
    lieu_de_naiss :Ville;
    paiement_admission;
    parcours;
    promotion;
    nif;
    cin;
    granted
    enabled
    nom_pere;
    nom_mere;
    fonction;
    identifiant;
    matricule;
    bank_account_HTG;
    bank_account_USD;
    orphelin;
    religion;
    id_img;
    exclude;
    pob;
    current_promo;
pin;
}
