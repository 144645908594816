<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="col-md-12 mb-2">
            <h4 class="mb-3  text-center"> Evenement</h4>
        </div>
        <div class="col-md-12 mb-2">
            <div class="row card">

                <div *ngIf="response.active && response.view==10" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                    {{response.message}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
     <span aria-hidden="true">&times;</span>
   </button>
                </div>

                <div class="card-body">
                    <ul class="nav nav-tabs" id="dropdwonTab">
                        <li class="nav-item">
                            <a class="nav-link active" id="cong-tab" data-toggle="tab" href="#cong" aria-controls="cong" aria-expanded="true">Jours fériés </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="home-tab" data-toggle="tab" href="#home" aria-controls="home" aria-expanded="true">Annuel</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="mens-tab" data-toggle="tab" href="#mens" aria-controls="mens" aria-expanded="false">Mensuel</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="heb-tab" data-toggle="tab" href="#heb" aria-controls="heb" aria-expanded="false">Hebdomadaire</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="day-tab" data-toggle="tab" href="#day" aria-controls="day" aria-expanded="false">Journalier</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle " data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"> Heure</a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" id="dropdown1-tab" href="#dropdown1" data-toggle="tab" aria-controls="dropdown1" aria-expanded="true">Fixe</a>
                                <a class="dropdown-item" id="dropdown2-tab" href="#dropdown2" data-toggle="tab" aria-controls="dropdown2" aria-expanded="true">Avec durrée</a>
                            </div>
                        </li>

                    </ul>
                    <div class="tab-content px-1 pt-1" id="dropdwonTabContent">

                        <div role="tabpanel" class="tab-pane active" id="cong" aria-labelledby="cong-tab" aria-expanded="true">
                            <div class="row col-md-12 pt-4">
                                <div class="col-md-4 ">
                                    <h4 class="p-2 text-center"> Ajouter Jour ferié</h4>
                                    <div *ngIf="response.active && response.view==1" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                                        {{response.message}}
                                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
     <span aria-hidden="true">&times;</span>
   </button>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="recipient-name-1" class="col-form-label">Nom</label>
                                        <input type="text" class="form-control" [(ngModel)]="event.name" />
                                    </div>
                                    <div class="row col-md-12">
                                        <div class="form-group col-md-6">
                                            <label for="recipient-name-1" class="col-form-label">jour</label>
                                            <input type="text" class="form-control" [(ngModel)]="event.day" />
                                        </div>
                                        <div class="form-group col-md-6">
                                            <label for="recipient-name-1" class="col-form-label">mois</label>
                                            <input type="text" class="form-control" [(ngModel)]="event.month" />
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="recipient-name-1" class="col-form-label">Details</label>
                                        <textarea class="form-control" [(ngModel)]="event.details"></textarea>
                                    </div>
                                    <div class="form-group col-md-12 pl-2" style="margin-top:1.8%;">
                                        <button class="btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="addEventJF()" [disabled]='loading '>
                                                       <span *ngIf='loading' class="spinner-border spinner-border-sm mr-1"></span>Ajouter </button>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <mwl-demo-utils-calendar-header [(view)]="view" [(viewDate)]="viewDate">
                                    </mwl-demo-utils-calendar-header>
                                    <div [ngSwitch]="view">
                                        <mwl-calendar-month-view *ngSwitchCase="'month'" [locale]="locale" [viewDate]="viewDate" [events]="events" [activeDayIsOpen]="true">
                                        </mwl-calendar-month-view>
                                        <mwl-calendar-week-view *ngSwitchCase="'week'" [locale]="locale" [viewDate]="viewDate" [events]="events">
                                        </mwl-calendar-week-view>
                                        <mwl-calendar-day-view *ngSwitchCase="'day'" [locale]="locale" [viewDate]="viewDate" [events]="events">
                                        </mwl-calendar-day-view>
                                    </div>
                                </div>

                            </div>


                        </div>
                        <div role="tabpanel" class="tab-pane" id="home" aria-labelledby="home-tab" aria-expanded="true">
                            <p>Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard locavore.
                            </p>
                        </div>
                        <div class="tab-pane" id="mens" role="tabpanel" aria-labelledby="mens-tab" aria-expanded="false">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                        </div>
                        <div class="tab-pane" id="heb" role="tabpanel" aria-labelledby="heb-tab" aria-expanded="false">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                        </div>
                        <div class="tab-pane" id="day" role="tabpanel" aria-labelledby="day-tab" aria-expanded="false">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            </p>
                        </div>
                        <div class="tab-pane " id="dropdown1" role="tabpanel" aria-labelledby="dropdown1-tab" aria-expanded="true">
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Enim tempora voluptas fuga exercitationem sunt officiis hic accusantium quos, sapiente harum voluptatum facere accusamus impedit at quia ipsum, suscipit praesentium
                                natus!
                            </p>
                        </div>
                        <div class="tab-pane" id="dropdown2" role="tabpanel" aria-labelledby="dropdown2-tab" aria-expanded="false">
                            <p>Etsy mixtape wayfarers, ethical wes anderson tofu before they sold out mcsweeney's organic lomo retro fanny pack lo-fi farm-to-table readymade. Messenger bag gentrify pitchfork tattooed craft beer, iphone skateboard locavore.
                            </p>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        <!-- end of col -->
        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->
