export class Etab {
  code: any;
  logo: any;
  background: string;
  name: any;
  cat_etab: any;
  annee_fond: any;
  email: any;
  phone_a: any;
  phone_b: any;
  phone_c: any;
  phone_d: any;
  description: any;
  website: any;
  adresse: any;
  moy_total: any;
  moy_accept: any;
  moy_reprise: any;
  // tslint:disable-next-line: variable-name
  moy_exc: any;
  // info respo
  nom_resp: any;
  desc_resp: any;
  phone_e: any;
  phone_f: any;
  phone_g: any;
  phone_h: any;
  adresse_resp: any;
  email_resp: any;
  exec: any;
  ona;
  iri;
  fdu;
  cas;
  salairy_min;
  salairy_max;
  prix_admis;
  iri_1;
  iri_2;
  iri_3;
  iri_4;
  cfgdct;
  assure_mal;
  currency;
  teacher;
  student;
  token;
  fb;
  insta;
  twitter;
  youtube;
  linkedin;
  whatsapp;
  mission;
  vision;
  valeur;
  ncours;
  nprog;
  nmat;
  ncmat;
  nbre_ctrl;
  frag_name;
  type_reprise;
  reprise;
  over_config;
  current_year;
  id_img;
  start_time;
  end_time;
  code_six; code_neuv; code_rheto; code_philo;
  tuts;app;
  pfail_msg;
  ppass_msg;
  fail_msg;
  pass_msg;
  fail_msg_rep;
  pass_msg_rep;
  mode_paiement;
  smsGateway;
  usernameGateway;
  passGateway;
  tokenGateway;
}
