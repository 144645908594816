<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="row ">
            <div class="col-md-12 mb-2">
                <h3 class="mb-3  text-center">Gestion des fournitures</h3>
            </div>
            <div class="col-md-4">
                <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                    {{response.message}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
                </div>
                <div class="col-md-12 card" style="margin-bottom:2%;" *ngIf="!es">

                    <div class="card-body">
                        <h4 class="text-center mb-3">Ajouter Founiture Scolaire</h4>
                    </div>

                    <div class="row col-md-12">

                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">Fourniture</label>
                            <input type="text" class="form-control" [(ngModel)]="s.name" />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">Type de fourniture</label>
                            <select class="form-control" [(ngModel)]="s.type_fn">
                             <option value="1">Materiel</option>
                             <option value="2">Livre</option>
                           </select>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">En vente a l'ecole</label>
                            <select class="form-control" [(ngModel)]="s.sell_at">
     <option value="true">Oui</option>
     <option value="false" Selected>Non</option>
   </select>
                        </div>
                        <div class="form-group col-md-12" *ngIf="s.type_fn==1">
                            <label for="recipient-name-1" class="col-form-label">Unité</label>
                            <input type="text" class="form-control" [(ngModel)]="s.unity" />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">Description</label>
                            <textarea class="form-control col-md-12" [(ngModel)]="s.description"></textarea>
                        </div>
                        <div class="form-group col-md-12">
                            <button class="btn btn-primary  btn-rounded mt-3" (click)="addSalle()" [disabled]='loading || s.code === "" || s.name === ""  '>
                <span *ngIf='loading' class="spinner-border spinner-border-sm mr-1"></span>
                Ajouter
              </button>
                        </div>
                        <div class="col-md-12 mt-4">
                            <a class="btn  btn-block btn-warning  m-2" routerLink="/app/ficheFourniture"> Fournitures scolaires  </a>
                        </div>
                    </div>

                </div>

                <div class="card " style="margin-bottom:2%;" *ngIf="es">

                    <div class="card-body">
                        <h4 class="text-center mb-3">Modifier Fourniture Scolaire</h4>
                        <button type="button" class="close" (click)="closeEditSalle()">
              <span aria-hidden="true">&times;</span>
            </button>
                    </div>

                    <div class="row col-md-12">
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">Fourniture</label>
                            <input type="text" class="form-control" [(ngModel)]="es.name" />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">Type de fourniture</label>
                            <input type="text" class="form-control" [(ngModel)]="es.type_fn" />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">En vente a l'ecole</label>
                            <select class="form-control" [(ngModel)]="es.sell_at">
                             <option value="true">Oui</option>
                             <option value="false" Selected>Non</option>
                            </select>
                        </div>
                        <div class="form-group col-md-12" *ngIf="es.type_fn==1">
                            <label for="recipient-name-1" class="col-form-label">Unité</label>
                            <input type="text" class="form-control" [(ngModel)]="es.unity" />
                        </div>
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1" class="col-form-label">Description</label>
                            <textarea class="form-control col-md-12" [(ngModel)]="es.description"></textarea>
                        </div>

                        <div class="form-group col-md-12">
                            <button class="btn btn-primary  btn-rounded mt-3" (click)="editSubmitSalle()" [disabled]='loading || es.code === "" || es.name === ""  '>
                <span *ngIf='loading' class="spinner-border spinner-border-sm mr-1"></span>
                Modifier
              </button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-8 card">
                <div *ngIf="ldata" class="text-center">
                    <div class="spinner-bubble spinner-bubble-warning m-5"></div>
                </div>
                <div class="col-md-12 card-body" *ngIf="!ldata">
                    <h4 class="text-center mb-3">La liste des fournitures scolaires</h4>
                    <div class="col-md-12">
                        <div class="col-md-4" *ngFor="let obj of salles; let i = index" style="display:inline-block;">
                            <div class="card card-icon mb-4">
                                <i class=""></i>
                                <div class="card-body text-center">
                                    <p class="text-muted mt-2 mb-2">

                                        <b> <i class="i-Shop-4" *ngIf="obj.sell_at"></i> <br> <span
                                            class="badge badge-primary m-1" *ngIf="obj.type_fn==1">{{obj.unity}}</span>
                                          {{ obj.name}} </b><br />
                                        <span class="badge badge-warning m-1" *ngIf="obj.type_fn==1">Materiel</span>
                                        <span class="badge badge-warning m-1" *ngIf="obj.type_fn==2">Livre</span>


                                    </p>
                                    <p class="text-muted mt-2 mb-2">
                                        <b> {{ obj.desc}}</b>
                                    </p>
                                    <div class="col-md-12">
                                        <a (click)="editSalle(obj,i)" class="text-success mr-2">
                                            <i class="nav-icon i-Pen-2 font-weight-bold"></i>
                                        </a>
                                        <a (click)="delSalle(obj,i)" class="text-danger mr-2">
                                            <i class="nav-icon i-Close-Window font-weight-bold"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- end of row-->
        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
</div>