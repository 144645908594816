import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-doc',
  templateUrl: './add-doc.component.html',
  styleUrls: ['./add-doc.component.css']
})
export class AddDocComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
