<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="row mb-4">
            <div class="col-md-12 mb-2">
                <h4 class="mb-3  text-center"> Ajouter etudiant (elève) dans la promotion {{PROMO?.code}}</h4>
                <h5 class="text-danger text-center">
                    <a class="text-danger" routerLink="/app/promoDetails/{{ID}}">Retourner</a> </h5>
                <h5 class="text-danger text-center">
                    <a class="text-warning" routerLink="/app/classe/1">Voir la liste des promotions</a> , <a class="text-warning" routerLink="/app/configPromo">Configuration promotion</a>
                </h5>
            </div>
            <div class="col-md-12 text-center text-light m-2 p-2">

                <a (click)="mpage=1" [className]="mpage==1 ? 'btn btn-secondary m-2' : 'btn btn-primary m-2'">
                  Etudiants de cette promotion</a>
                <a (click)="setED()" [className]="mpage==0 || mpage==undefined || mpage==null ? 'btn btn-secondary m-2' : 'btn btn-primary m-2'">
   Etudiants disponibles</a>
                <a (click)="mpage=2" [className]="mpage==2 ? 'btn btn-secondary m-2' : 'btn btn-primary m-2'">
   Etat de compte</a>
                <a href="/app/asop/{{ID}}" target="_blank" style="margin-right:1%;" class="btn btn-sm btn-primary ">
  Imprimer la liste des etudiants </a>

            </div>
            <div class="col-md-12 mb-4">
                <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                    {{response.message}}
                </div>
            </div>

            <div class="col-md-12 mb-4" *ngIf="mpage==0 || mpage==undefined || mpage==null">

                <div class="card text-left">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Les étudiants disponibles ({{users?.length}})</h4>
                        <div class=" row" style="float:right;">
                            <div class="form-group col-md-12 row">
                                <div class="col-md-4" style="float:left;">
                                    <label for="recipient-name-1" class="col-form-label">Rechercher</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" (keyup)="onKeyV2($event)">
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="zero_configuration_table" class="display table table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom et prénom</th>
                                        <th>Classe</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let o of users; let i = index">
                                        <td>{{o.code}}</td>
                                        <td>
                                            {{o.lastName}} {{o.firstName}}</td>
                                        <td>{{o.classe}}</td>
                                        <td>


                                            <div class="row">
                                                <div class="form-group col-md-8">

                                                    <select id="OP" [(ngModel)]="option[i]" class="form-control">
                                                      <option  [selected]="iop===0" [ngValue]="s.id" *ngFor="let s of OP; let iop = index">
                                                        {{s.name}}
                                                      </option>
                                                    </select>
                                                </div>
                                                <div class=" form-group col-md-4">
                                                    <button [disabled]='loading[i] || !option[i]  ' (click)="addSTP(o,option[i],i)" class="btn btn-primary">
                                                                <span *ngIf='loading[i]' class="spinner-border spinner-border-sm mr-1"></span>
                                                                  <i class="nav-icon i-Add"></i>
                                                    </button>

                                                </div>

                                            </div>


                                        </td>
                                    </tr>


                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom et prénom</th>
                                        <th>Classe</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mb-4" *ngIf="mpage==1">
                <div class="card text-left">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Les étudiants de cette promotion ({{STUDS?.length}})</h4>
                        <div class=" row" style="float:right;">
                            <div class="form-group col-md-12 row">
                                <div class="col-md-4" style="float:left;">
                                    <label for="recipient-name-1" class="col-form-label">Rechercher</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" autocomplete="off" (keyup)="onKeyUp($event)">
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="zero_configuration_table" class="display table table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom et prénom</th>
                                        <th>Sexe</th>
                                        <th>Discipline</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let po of STUDS; let i = index">
                                        <td>{{po.code_student}}</td>
                                        <td [className]="!po.actived ? ' text-danger ' : 'text-success'">{{po.nom}} {{po.pnom}}
                                        </td>
                                        <td>{{po.sexe}}</td>
                                        <td>
                                            <label class="alert alert-info" *ngIf="msg[i]">{{msg[i]}}</label>
                                            <div class="row col-md-12">
                                                <div class="form-group col-md-12">
                                                    <label for="recipient-name-1" class="col-form-label">Mention</label>
                                                    <input type="text" class="form-control" [(ngModel)]="po.mention" />
                                                    <button class=" ml-4 btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="setDisc(po,i)" [disabled]='loading[i]'>
                                                <span *ngIf='loading[i]' class="spinner-border spinner-border-sm mr-1"></span>
                                                modifier
                                              </button>
                                                </div>

                                            </div>

                                        </td>
                                        <td>
                                            <button *ngIf="!PROMO.setup_over" [disabled]='dloading[i]' (click)="remSTP(po,i)" class="mr-2 btn btn-sm btn-danger">
                                                  <span *ngIf='dloading[i]' class="spinner-border spinner-border-sm mr-1"></span>
                                                  <i class="nav-icon i-Close"></i>
                                            </button>
                                            <a class="btn btn-sm btn-primary" routerLink="/app/payment/{{po.id}}">
                                                <i class="i-Money-2"></i></a>
                                            <a routerLink="/app/bulletinGen/{{ID}}/{{po.id}}" class="btn btn-sm btn-primary  m-2">
                                                <i class="i-File-Bookmark"></i>
                                            </a>
                                        </td>
                                    </tr>


                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom et prénom</th>
                                        <th>Sexe</th>
                                        <th>Discipline</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 row " *ngIf="mpage==2">
                <div class="col-md-4 mb-4">
                    <div class="col-md-12">
                        <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body text-center">
                                <i class="i-Money-2"></i>
                                <div class="content">
                                    <p class="text-muted mt-2 mb-0">Possiblité de gain</p>
                                    <p class="text-primary text-24 line-height-1 mb-2">{{rapp?.max_gain?.toFixed(2)}} HTG
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body text-center">
                                <i class="i-Money-2"></i>
                                <div class="content">
                                    <p class="text-muted mt-2 mb-0">Caisse</p>
                                    <p class="text-primary text-24 line-height-1 mb-2">{{rapp?.gain?.toFixed(2)}} HTG
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
                            <div class="card-body text-center">
                                <i class="i-Money-2"></i>
                                <div class="content">
                                    <p class="text-muted mt-2 mb-0">A Gagné</p>
                                    <p class="text-primary text-24 line-height-1 mb-2">
                                        {{ rapp?.to_gain?.toFixed(2) }} HTG</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class=" col-md-8 card text-left">
                    <div class="card-body">
                        <div class="col-md-12 row mb-4">
                            <div class="col-md-2">
                                <button class="btn btn-primary" [useExistingCss]="true" printSectionId="print-section" ngxPrint>print</button>
                            </div>
                            <div class="col-md-4">
                                <label for="recipient-name-1" class="col-form-label">Rechercher</label>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" autocomplete="off" (keyup)="onKeyUp($event)">
                            </div>
                        </div>
                        <div id="print-section">
                            <div class="row col-md-12 mt-4 mb-4 p-0 text-center">
                                <h4 class="card-title mb-3 text-center">Etat de compte </h4>
                                <h5 class="mb-3  text-center"> Promotion {{PROMO?.code}}</h5>
                            </div>
                            <div class="table-responsive.mt-4" *ngFor="let user of STUDS; let s = index">
                                <table class="display my-table table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th> Etat de compte : <a routerLink="/app/configPaiementStudent/{{user.id}}/{{ID}}">{{user.nom}} {{user.pnom}}</a>
                                            </th>
                                            <th>
                                                <p *ngIf="user.bourse==0">Aucune bourse</p>
                                                <p *ngIf="user.bourse==1">Boursier</p>
                                                <p *ngIf="user.bourse==2">Demie bourse</p>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>Code</th>
                                            <th>A payer</th>
                                            <th>Déjà Payé</th>
                                            <th>Balance</th>
                                            <th>Type</th>

                                        </tr>
                                        <tr *ngFor="let o of user.pversement; let i = index">
                                            <td>{{o.name.split(" ")[0]}} {{o.name.split(" ")[1] }} - {{o.pos}}</td>
                                            <td>{{o.montant_to_pay.toFixed(2)}} HTG </td>
                                            <td [className]="o.montant_to_pay>o.montant_pay ? ' text-danger ' : 'text-success'">
                                                {{o.montant_pay}} HTG</td>
                                            <td> {{o.montant_to_pay - o.montant_pay}} HTG</td>
                                            <td>
                                                <p *ngIf="o.type_verse==1">Basique</p>
                                                <p *ngIf="o.type_verse==3">Facultatif</p>
                                                <p *ngIf="o.type_verse==2">Operationel</p>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                                <div class="separator-breadcrumb border-top m-2"></div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <!-- end of col -->
        </div>

        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->
<!--=============== End app-admin-wrap ================-->