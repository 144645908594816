<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="col-md-12 mb-2">
            <h4 class="mb-3  text-center">Gestion des finances </h4>
        </div>
        <div class="col-md-12 mb-4">
            <div class="card text-left">

                <div class="card-body">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="home-basic-tab" data-toggle="tab" href="#homeBasic" role="tab" aria-controls="homeBasic" aria-selected="true">Economat</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="profile-basic-tab" data-toggle="tab" href="#profileBasic" role="tab" aria-controls="profileBasic" aria-selected="false">Payroll</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="contact-basic-tab" data-toggle="tab" href="#contactBasic" role="tab" aria-controls="contactBasic" aria-selected="false">Caisse</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeBasic" role="tabpanel" aria-labelledby="home-basic-tab">
                            <div class="col-lg-12 col-md-12">
                                <div class="row">

                                    <div class="col-md-4">
                                        <div class="card card-icon mb-4" routerLink="/app/paieAdmis">
                                            <div class="card-body text-center">
                                                <i class="i-File-Clipboard-File--Text"></i>
                                                <p class="text-muted mt-2 mb-2">Admission</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-4">
                                        <div class="card card-icon mb-4" routerLink="/app/parcours">
                                            <div class="card-body text-center">
                                                <i class="i-Calendar"></i>
                                                <p class="text-muted mt-2 mb-2">Mensualité / autres</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="card card-icon mb-4" routerLink="/app/mesPaiements">
                                            <div class="card-body text-center">
                                                <i class="i-Money"></i>
                                                <p class="text-muted mt-2 mb-2">Historique paiement</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="card card-icon mb-4" routerLink="/app/configPaiement">
                                            <div class="card-body text-center">
                                                <i class="i-Gear"></i>
                                                <p class="text-muted mt-2 mb-2">Configuration paiement</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="card card-icon mb-4" routerLink="/app/rapportPaiement">
                                            <div class="card-body text-center">
                                                <i class="i-Bar-Chart-2"></i>
                                                <p class="text-muted mt-2 mb-2">Rapport</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="profileBasic" role="tabpanel" aria-labelledby="profile-basic-tab">
                            <div class="col-md-12 row">
                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/payroll/create">
                                        <div class="card-body text-center">
                                            <i class="i-Add"></i>
                                            <p class="text-muted mt-2 mb-2">Nouveau Payroll</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/payroll/finish">
                                        <div class="card-body text-center">
                                            <i class="i-Folder-Block"></i>
                                            <p class="text-muted mt-2 mb-2">Payroll en cours</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/payroll/config">
                                        <div class="card-body text-center">
                                            <i class="i-Gear"></i>
                                            <p class="text-muted mt-2 mb-2">Configuration Payroll</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/payroll/history">
                                        <div class="card-body text-center">
                                            <i class="i-Folder-Block"></i>
                                            <p class="text-muted mt-2 mb-2">Historique Payroll</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/payroll/stat">
                                        <div class="card-body text-center">
                                            <i class="i-Line-Chart-3"></i>
                                            <p class="text-muted mt-2 mb-2">Statistique Payroll</p>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="tab-pane fade" id="contactBasic" role="tabpanel" aria-labelledby="contact-basic-tab">
                            <div class="col-md-12 row">
                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/caisse/transaction">
                                        <div class="card-body text-center">
                                            <i class="i-Add"></i>
                                            <p class="text-muted mt-2 mb-2">Nouvelle transaction</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3">
                                    <div class="card card-icon mb-4" routerLink="/app/caisse/htransaction">
                                        <div class="card-body text-center">
                                            <i class="i-Folder-Block"></i>
                                            <p class="text-muted mt-2 mb-2">Historique transaction</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->
