<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!--============== Body content start ===============-->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="ml-4 col-md-12 mb-2 text-center">
            <h4 class="mb-3  text-center">Gestion de progression</h4>
            <h5 class="mb-3  text-center" *ngIf="cpromo">{{ PROMO?.code}}-({{PROMO?.id}})</h5>
            <h5 class="text-warning text-center" *ngIf="apromo?.length>0"> {{index}}/{{tpromo}} </h5>
            <a href="/app/asop/{{ID}}" *ngIf="cpromo" target="_blank" class="btn btn-sm btn-primary m-2">
        Imprimer la liste des etudiants </a>
            <a class="btn btn-sm btn-warning m-2 " *ngIf="cpromo" target="_blank" routerLink="/app/promoDetails/{{ID}}">Voir
        cette
        promotion</a>
            <div class="form-group col-md-8 p-2 card" style="margin-left:18%;" *ngIf="apromo?.length>0">
                <label for="recipient-name-1" class="col-form-label">Changer de Promotion</label>
                <select [(ngModel)]="ix" class="form-control" (change)="getCPromo(ix)">
                         <option [ngValue]="i" *ngFor="let a of apromo; let i = index"> {{a.code}}</option>
                       </select>
            </div>
        </div>
        <div class="col-md-12" *ngIf="year">
            <div class="alert alert-card alert-warning text-center" role="alert">
                Il n'y a pas pas autres années academiques
            </div>
        </div>
        <div class="col-md-12 text-center" *ngIf="apromo?.length == 0 && over">
            <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                {{response.message}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="alert alert-card alert-warning text-center" role="alert">
                Il n'y a pas de promotions dans cette année academique

            </div>
            <a class="btn btn-sm btn-danger m-2 text-center " routerLink="/app/management"> retourner </a>
            <button [disabled]="lpromo" class="btn btn-block btn-success mt-2" (click)="genPromo()">Générer les
         promotions de cette année</button>
        </div>

        <div class="col-md-12" *ngIf="apromo?.length>0">

            <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                {{response.message}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>

            <div class="col-md-12  pt-2 mt-2">
                <div class="col-md-8 card pt-2 mt-2" style="margin-left:20%;" *ngIf="PROMO?.prev_promo==null || PROMO.prev_promo==''">
                    <div class="col-md-12 ">
                        <h4 class="mb-2  text-center"> Selectionnez la promotion précedente</h4>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="recipient-name-1">Choisissez la promotion </label>
                            <option></option>
                            <select id="niv" (change)='change()' class="form-control" [(ngModel)]="ppromo">
                            <option [ngValue]="f" *ngFor="let f of PROMOS">{{ f?.code.split("(")[0] }} </option>
                            </select>
                            <label class="text-warning">{{PROMOS.length}} promotion(s) trouvée(s)</label>
                        </div>
                        <div class="form-group col-md-12" *ngIf="option">
                            <button style="float:right;" class="btn btn-primary text-right push-right pull-right" (click)="setPromoPrev(ppromo)" [disabled]="iloading">Configurer</button>
                        </div>
                    </div>

                </div>

                <div class="col-md-12 pt-2 mt-2" *ngIf="ready">
                    <div class="col-md-12 text-center">
                        <div class="card p-4 ">
                            <div class="card-body">
                                <div class="col-md-12 ">
                                    <h4 class="mb-1  text-center">Promotion précedente: {{PROMO?.prev_promo_name}}</h4>
                                    <h5 class="mb-1  text-center">Moyenne accepté:{{PROMO?.moy_accept}} </h5>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div class="row ">
                                        <div class="col-md-12 row">
                                            <div class="form-group col-md-3">
                                                <select id="OP" [(ngModel)]="moption" class="form-control">  <option [selected]="iop===0" [ngValue]="s.id"
                                            *ngFor="let s of OP; let iop = index">
                                            {{s.name}}
                                          </option>
                                        </select>
                                            </div>
                                            <div class="col-md-2 text-left p-1">
                                                <button class="btn btn-primary" [disabled]='mloading' (click)="push()"> <i
                                            class="i-Upload"></i></button>
                                            </div>
                                            <div class="col-md-2 text-left pr-2">
                                                <button class="btn btn-primary m-2" [disabled]='mloading' (click)="close()"> Fermer
                                          tout</button>
                                            </div>
                                            <div class="col-md-2 text-right p-1">
                                                <button class="btn btn-danger m-2" [disabled]='mloading' (click)="resetProg()"> reinitialiser</button>
                                            </div>
                                            <div class="col-md-2 text-right p-1">
                                                <button class="btn btn-primary m-2" (click)="setMoyGen()"> Valider resultats </button>
                                            </div>
                                        </div>
                                        <div class="col-md-12 row m-2">

                                            <div class="col-md-4 text-right">
                                                <label for="recipient-name-1" class="col-form-label">Rechercher</label>
                                            </div>
                                            <div class="col-md-8">
                                                <input type="text" class="form-control" autocomplete="off" (keyup)="onKeyUp($event)">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="table-responsive">
                                    <table id="zero_configuration_table" class="display table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th><input type="checkbox" [(ngModel)]="all" id="all" (change)="onCheckboxChangeAll($event)" class="checkbox"> </th>
                                                <th>Code</th>
                                                <th>Nom et prénom</th>
                                                <th>Moyenne Génerale</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let o of STUDS; let i = index">
                                                <td> <input *ngIf="o.actived" id="{{ i }}" [value]="o.id" [(ngModel)]="o.isSelected" (change)="onCheckboxChange($event,i)" type="checkbox" class="checkbox">
                                                </td>
                                                <td>{{o.code}}</td>
                                                <td>{{o.nom}} {{o.pnom}}</td>
                                                <td [className]="setMoy(o.note, o.total, PROMO.moy_total)>=PROMO.moy_accept ? ' text-success ' : 'text-danger'">
                                                    {{ setMoy(o.note, o.total, PROMO.moy_total) }}
                                                </td>
                                                <td>
                                                    <div class="row">
                                                        <div class="form-group col-md-6" *ngIf="o.actived">
                                                            <select id="OP" [(ngModel)]="option[i]" class="form-control">
                                                          <option [selected]="iop===0" [ngValue]="s.id"
                                                            *ngFor="let s of OP; let iop = index">
                                                            {{s.name}}
                                                          </option>
                                                        </select>
                                                        </div>
                                                        <div class=" form-group col-md-3">
                                                            <button *ngIf="o.actived" [disabled]='loading[i] || !option[i] ' (click)="addSTP(o,option[i],i)" class="btn btn-primary">

                                                <span *ngIf='loading[i]'
                                                  class="spinner-border spinner-border-sm mr-1"></span>
                                                <i class="nav-icon i-Add"></i>
                                              </button>

                                                        </div>
                                                        <div class="col-md-2 text-right mr-1">
                                                            <button class="btn btn-danger" *ngIf="o.actived" [disabled]='mloading' (click)="closeOne(o.id,i,false)">
<i class="i-Close-Window"></i> </button>
                                                            <button class="btn btn-success" *ngIf="!o.actived" [disabled]='mloading' (click)="closeOne(o.id,i,true)">
<i class="i-On-Air"></i> </button>
                                                        </div>


                                                    </div>


                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th><input type="checkbox" [(ngModel)]="all" id="all" (change)="onCheckboxChangeAll($event)" class="checkbox"></th>
                                                <th>Code</th>
                                                <th>Nom et prénom</th>
                                                <th>Moyenne Génerale</th>
                                                <th>Action</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div class="col-md-12 p-2">
                <button class="btn btn-danger" (click)="prev()" *ngIf="index>1" style="float:left;">Précedent</button>
                <button class="btn btn-success" (click)="next()" *ngIf="index<tpromo" style="float:right;">Suivant</button>
            </div>


            <app-footer></app-footer>
            <!-- fotter end -->
        </div>
        <!-- ============ Body content End ============= -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
