<!-- end of row-->
<div class="col-md-8" style="margin-left:16.66%;">
    <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
        {{response.message}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
    </div>
    <div class="col-md-12 mt-4">
        <div class="card p-2">
            <div class="col-md-12 p-2">
                <h3 class="text-center mb-3">Ajouter matiere</h3>
            </div>
            <div class="row col-md-12">
                <div class="form-group col-md-4">
                    <label for="recipient-name-1" class="col-form-label">Matieres</label>
                    <input type="text" class="form-control" [(ngModel)]="mat.name" />
                </div>
                <div class="form-group col-md-3">
                    <label for="recipient-name-1" class="col-form-label">Coef</label>
                    <input type="number" class="form-control" [(ngModel)]="mat.coef" />
                </div>
                <div class="form-group col-md-3">
                    <label for="recipient-name-1" class="col-form-label">Note total</label>
                    <input type="number" class="form-control" [(ngModel)]="mat.note_total" />
                </div>
                <div class="form-group col-md-2" style="margin-top:3%;">
                    <button class="btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="addMat()" [disabled]='loading || !mat.name || !mat.coef || !mat.note_total '>
                    <span *ngIf='loading' class="spinner-border spinner-border-sm mr-1"></span>
                    Ajouter
                  </button>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="col-md-12">

    <b class="card-title mb-3">La liste des matières</b>
    <div class="col-md-12">
        <div class="col-md-2 mt-4 mb-4" *ngFor="let a of mats,let i = index" style="display:inline-block;">
            <div class="card card-icon mb-4 p-2">
                <div class="text-right">
                    <i class="i-Close text-danger text-center text-right" (click)="del(a,i)"></i>
                </div>
                <div class="card-body text-center">
                    <i class="i-Book"></i>
                    <p class="text-muted mt-2 mb-2"> <b>{{a?.name}}</b></p>
                    <span class="badge badge-success">{{a?.note_total*a?.coef}}</span>

                </div>
            </div>
        </div>
    </div>

</div>