<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="col-md-12 mb-2">
            <h4 class="mb-3  text-center">Gestion de location </h4>
        </div>
        <!-- end of row-->
        <div class="col-md-8" style="margin-left:16.66%;">
            <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                {{response.message}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
            </div>
            <div class="col-md-12 mt-4" *ngIf="!cmat.id">
                <div class="card p-2">
                    <div class="col-md-12 p-2">
                        <h3 class="text-center mb-3">Ajouter Ville</h3>
                    </div>
                    <div class="row col-md-12">
                        <div class="form-group col-md-4">
                            <label for="recipient-name-1" class="col-form-label">Département</label>
                            <select [(ngModel)]="mat.etat" class="form-control">
                                  <option [value]="item.id" *ngFor="let item of etats">{{item.name}}</option>
                             </select>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Code</label>
                            <input type="text" class="form-control" [(ngModel)]="mat.code" />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Commune</label>
                            <input type="text" class="form-control" [(ngModel)]="mat.name" />
                        </div>
                        <div class="form-group col-md-2" style="margin-top:3%;">
                            <button class="btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="addMat()" [disabled]='loading || !mat.name || !mat.code '> <span *ngIf='loading' class="spinner-border spinner-border-sm mr-1"></span>
            Ajouter
          </button>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 mt-4" *ngIf="cmat.id">
                <div class="card p-2">
                    <div class="col-md-12 p-2">
                        <h3 class="text-center mb-3">Modifier Ville <i class="i-Close text-danger  text-right" (click)="close()"></i></h3>

                    </div>
                    <div class="row col-md-12">

                        <div class="form-group col-md-4">
                            <label for="recipient-name-1" class="col-form-label">Code</label>
                            <input type="text" class="form-control" [(ngModel)]="cmat.code" />
                        </div>
                        <div class="form-group col-md-5">
                            <label for="recipient-name-1" class="col-form-label">Commune</label>
                            <input type="text" class="form-control" [(ngModel)]="cmat.name" />
                        </div>
                        <div class="form-group col-md-3" style="margin-top:3%;">
                            <button class="btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="editMat(cmat.id)" [disabled]='loading || !cmat.name || !cmat.code '> <span *ngIf='loading' class="spinner-border spinner-border-sm mr-1"></span>modifier </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="col-md-12">

            <b class="card-title mb-3">La liste des villes</b>
            <div class="col-md-12">
                <div class="col-md-2 mt-4 mb-4" *ngFor="let a of villes; let i = index" style="display:inline-block;">
                    <div class="card card-icon mb-4 p-2">
                        <div class="text-right">
                            <i class="i-Close text-danger text-center text-right" (click)="del(a,i)"></i>
                        </div>
                        <div class="card-body text-center">
                            <i class="i-Map-Marker"></i>
                            <p class="text-muted mt-2 mb-2"> <b>{{a?.name}}</b></p>
                            <span class="badge badge-success text-center m-2">{{a.code}}</span>
                            <i class="icon i-Pen-2 m-2 text-light btn btn-primary btn-sm" (click)="edit(a,i);"></i>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- end of col -->
        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->
