import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-valider-nom',
  templateUrl: './valider-nom.component.html',
  styleUrls: ['./valider-nom.component.css']
})
export class ValiderNomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
