
export class Etab {
 constructor() {}

saveEtab(e,app){
  app.saveEtab(e);
}
getEtab(app){
  return app.getEtab();
}


}