<div class="app-admin-wrap">
    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div *ngIf="!users" class="text-center">
            <div class="spinner-bubble spinner-bubble-warning m-5"></div>
        </div>
        <div *ngIf="users && users.length>0" class="col-md-12">
            <div class="col-md-12 mb-4">
                <div class="card text-left">
                    <div class="card-body">

                        <div class="col-md-12">
                            <h4 class="card-title mb-3">Vos paiements</h4>
                            <div class="table-responsive">
                                <table id="zero_configuration_table" class="display table table-striped table-bordered" style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Promotion</th>
                                            <th>Montant Versé</th>
                                            <th>Montant Accepté</th>
                                            <th>Rémise</th>
                                            <th>Date paiement </th>
                                            <th>Type paiement </th>
                                            <th>Code Trans. </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let o of users; let i = index">
                                            <td>{{o.code_etudiant}}</td>
                                            <td>{{o.promotion}}</td>
                                            <td>{{o.pay_money}}G</td>
                                            <td>{{o.montant}}G</td>
                                            <td>{{o.remain}}G</td>
                                            <td>{{o.created_at.split("T")[0]}}</td>
                                            <td>{{o.type_paiement}}</td>
                                            <td>{{o.serie}}</td>
                                            <td>
                                                <button routerLink="/app/fichePay/{{o.id}}/{{o.id_parcours}}" class="btn btn-dark"> Voir fiche</button>
                                            </td>
                                        </tr>


                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>Code</th>
                                            <th>Promotion</th>
                                            <th>Montant Versé</th>
                                            <th>Montant Accepté</th>
                                            <th>Rémise</th>
                                            <th>Date paiement </th>
                                            <th>Type paiement </th>
                                            <th>Code Trans. </th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <nav aria-label="Page navigation example">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item " *ngIf="p?.pageNumber>0" (click)="changePage(page-1)">
                                        <a class="page-link">Précedent</a>
                                    </li>
                                    <li class="page-item" *ngFor="let i of tp" (click)="changePage(i.index)">
                                        <a class="page-link">{{i.page}}</a></li>
                                    <li class="page-item" *ngIf="page < (rtp-1)">
                                        <a class="page-link" (click)="changePage(page+1)">Suivant</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <!-- end of col -->
                </div>

                <app-footer></app-footer>
                <!-- fotter end -->
            </div>
            <!-- ============ Body content End ============= -->
        </div>
    </div>
</div>
<!--=============== End app-admin-wrap ================-->