<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>

        <div *ngIf="users" class="col-md-12">
            <div class="form-group col-md-12 row">
                <div class="col-md-12">
                    <button routerLink="/app/addProf" class="btn btn-dark">Ajouter enseignant</button>
                    <a (click)="page=0" [className]="page==0 || page==undefined || page==null ? 'btn btn-secondary m-2' : 'btn btn-primary m-2 text-light'">
      enseignant activé</a>
                    <a (click)="page=1" [className]="page==1 ? 'btn btn-secondary m-2' : 'btn btn-primary m-2 text-light'">
      enseignant non activé</a>
                </div>
                <div *ngIf="response.active" class="col-md-12 alert alert-card alert-{{response.state}} text-center mt-2 " role="alert">
                    {{response.message}}
                </div>
            </div>
            <div class="col-md-12 mb-4" *ngIf="page==0">
                <div class="card text-left">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Les enseignants activé ({{users.length}})</h4>
                        <div class=" row" style="float:right;">
                            <div class="form-group col-md-12 row">
                                <div class="col-md-4" style="float:left;">
                                    <label for="recipient-name-1" class="col-form-label">Rechercher</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" (keyup)="onKey($event)">
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="zero_configuration_table" class="display table table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                        <th>Prenom</th>
                                        <th>Sexe</th>
                                        <th>Date de Naissance</th>
                                        <th>Email</th>
                                        <th>Telehone</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let o of users; let i = index">
                                        <td>{{o.code}}</td>
                                        <td>{{o.lastName}}</td>
                                        <td>{{o.firstName}}</td>
                                        <td>{{o.sexe}}</td>
                                        <td>{{getDate(o.date_de_naiss)}}</td>
                                        <td>{{o.username}}</td>
                                        <td>{{o?.phone}}</td>
                                        <td>
                                            <button routerLink="/app/detailsProf/{{o.id}}" class=" mr-1 btn btn-primary">
                        <i class="nav-icon i-Eye"></i>
                      </button>
                                            <button routerLink="/app/cpass/{{o.id}}" class="ml-2 btn btn-primary">
   <i class="nav-icon i-Lock"></i>
 </button>
                                            <button routerLink="/app/editProf/{{o.id}}" class="ml-1 btn btn-primary">
   <i class="nav-icon i-Edit"></i>
 </button>
                                        </td>
                                    </tr>


                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                        <th>Prenom</th>
                                        <th>Sexe</th>
                                        <th>Date de Naissance</th>
                                        <th>Email</th>
                                        <th>Classe</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- end of col -->
            </div>
            <div class="col-md-12 mb-4" *ngIf="page==1">
                <div class="card text-left">
                    <div class="card-body">
                        <h4 class="card-title mb-3">Les enseignants non activé ({{nusers.length}})</h4>
                        <div class=" row" style="float:right;">
                            <div class="form-group col-md-12 row">
                                <div class="col-md-4" style="float:left;">
                                    <label for="recipient-name-1" class="col-form-label">Rechercher</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" (keyup)="nonKey($event)">
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="zero_configuration_table" class="display table table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                        <th>Prenom</th>
                                        <th>Sexe</th>
                                        <th>Date de Naissance</th>
                                        <th>Email</th>
                                        <th>Telehone</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let o of nusers; let i = index">
                                        <td>{{o.code}}</td>
                                        <td>{{o.lastName}}</td>
                                        <td>{{o.firstName}}</td>
                                        <td>{{o.sexe}}</td>
                                        <td>{{getDate(o.date_de_naiss)}}</td>
                                        <td>{{o.username}}</td>
                                        <td>{{o?.phone}}</td>
                                        <td>
                                            <button routerLink="/app/detailsProf/{{o.id}}" class=" mr-1 btn btn-primary">
                   <i class="nav-icon i-Eye"></i>
                 </button>
                                            <button routerLink="/app/cpass/{{o.id}}" class="ml-2 btn btn-primary">
                   <i class="nav-icon i-Lock"></i>
                 </button>
                                            <button routerLink="/app/editProf/{{o.id}}" class="ml-1 btn btn-primary">
                   <i class="nav-icon i-Edit"></i>
                 </button>
                                            <button class="m-1 btn btn-success" (click)="activer(o,true,o.id,i)">
                  Activé
                </button>
                                        </td>
                                    </tr>


                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Code</th>
                                        <th>Nom</th>
                                        <th>Prenom</th>
                                        <th>Sexe</th>
                                        <th>Date de Naissance</th>
                                        <th>Email</th>
                                        <th>Classe</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- end of col -->
            </div>
            <app-footer></app-footer>
            <!-- fotter end -->
        </div>
        <!-- ============ Body content End ============= -->
    </div>
    <!--=============== End app-admin-wrap ================-->
