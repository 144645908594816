<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>

        <div class="col-md-12 row">
            <div class="col-md-3">
                <div class="card card-icon mb-4" target="_blank" routerLink="/app/allStudent">
                    <div class="card-body text-center">
                        <i class="i-Line-Chart-3"></i>
                        <p class="text-muted mt-2 mb-2">Liste des etudiants par promotion</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-icon mb-4" target="_blank" routerLink="/app/sexeStat">
                    <div class="card-body text-center">
                        <i class="i-Line-Chart-3"></i>
                        <p class="text-muted mt-2 mb-2">Nombres etudiants par promotion</p>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="card card-icon mb-4" target="_blank" routerLink="/app/etatDeCompte">
                    <div class="card-body text-center">
                        <i class="i-Line-Chart-3"></i>
                        <p class="text-muted mt-2 mb-2">Etat de compte</p>
                    </div>
                </div>
            </div>

        </div>


        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->