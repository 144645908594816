<div class="app-admin-wrap" id="page-top">
    <nav class="navbar navbar-expand-lg bg-primary text-uppercase fixed-top" id="mainNav">
        <div class="container">
            <a class="navbar-brand js-scroll-trigger" href="#page-top"></a>
            <button class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    Menu
                    <i class="fas fa-bars"></i>
                </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" pageScroll href="#home">Accueil</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" pageScroll href="#about">A propos</a></li>
                    <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" pageScroll href="#contact">Contactez-nous</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead  text-white text-center" style="min-height:600px;" id="home">
        <div class="container d-flex align-items-center flex-column">
            <!-- <img class="masthead-avatar mb-5" *ngIf="logo" height="100" class="card" [src]="logo" alt=""> -->
            <!-- <img class="masthead-avatar mb-5" height="100" *ngIf="!logo" class="card" [src]="getLocalImg('default.png')" alt=""> -->
            <!-- Masthead Heading -->
            <h3 class="masthead-heading text-uppercase mb-0">{{etab?.name}}</h3>
            <!-- Icon Divider-->
            <div class="divider-custom divider-light">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>

            <p class="text-center mt-5" *ngIf="url || !node">
                <a routerLink="/app/login" class="btn btn-primary btn-xl text-center m-2">Portal</a>
                <button *ngIf="!url && node" (click)="setBackend()" class="btn btn-secondary btn-xl text-center m-2">reconfiguration</button>
            </p>
            <p *ngIf="!url && node" class="masthead-subheading font-weight-light mb-0"> Svp, Veuillez contacter l'administrateur</p>

            <!-- Masthead Subheading-->
        </div>
    </header>

    <!-- About Section-->
    <section class="page-section bg-primary text-white mb-0" id="about">
        <div class="container">
            <!-- About Section Heading-->
            <h3 class="page-section-heading text-center text-uppercase text-white">A propos {{etab?.name}}</h3>
            <!-- Icon Divider-->
            <div class="divider-custom divider-light">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- About Section Content-->
            <div class="row ">
                <div class="col-lg-12 text-center">
                    <p class="lead">{{etab?.description}}</p>
                </div>
            </div>
            <!-- About Section Button-->

        </div>
    </section>
    <!-- Contact Section-->
    <section class="page-section" id="contact">
        <div class="container">
            <!-- Contact Section Heading-->
            <h3 class="page-section-heading text-center text-uppercase text-secondary mb-0">Contactez-nous</h3>
            <!-- Icon Divider-->
            <div class="divider-custom">
                <div class="divider-custom-line"></div>
                <div class="divider-custom-icon"><i class="fas fa-star"></i></div>
                <div class="divider-custom-line"></div>
            </div>
            <!-- Contact Section Form-->
            <div class="row">
                <div class="col-lg-8 mx-auto">
                    <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                        {{response.message}}
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
       aria-hidden="true">&times;</span></button>
                    </div>
                    <!-- To configure the contact form email address, go to mail/contact_me.php and update the email address in the PHP file on line 19.-->
                    <div id="contactForm">
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Nom complet</label>
                                <input [(ngModel)]="c.name" class="form-control" id="name" type="text" placeholder="Nom complet" required="required" data-validation-required-message="Please enter your name." />
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Email </label>
                                <input [(ngModel)]="c.email" class="form-control" id="email" type="email" placeholder="Email" required="required" data-validation-required-message="Please enter your email address." />
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Téléphone</label>
                                <input [(ngModel)]="c.phone" class="form-control" id="phone" type="tel" placeholder="Téléphone" required="required" data-validation-required-message="Please enter your phone number." />
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <div class="control-group">
                            <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                <label>Message</label>
                                <textarea class="form-control" [(ngModel)]="c.message" id="message" rows="5" placeholder="Message" required="required" data-validation-required-message="Please enter a message."></textarea>
                                <p class="help-block text-danger"></p>
                            </div>
                        </div>
                        <br />
                        <div id="success"></div>
                        <div class="form-group">
                            <button class="btn btn-primary btn-xl" (click)="addContact()">envoyer</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Footer-->
    <footer class="footer bg-primary text-center">
        <div class="container">
            <div class="row">
                <!-- Footer Location-->
                <div class="col-lg-4 mb-5 mb-lg-0">
                    <h4 class="text-uppercase mb-4" style="color:white;">Addresse</h4>
                    <p class="lead mb-0">
                        {{etab?.adresse}}
                    </p>
                </div>
                <!-- Footer Social Icons-->
                <div class="col-lg-4 mb-5 mb-lg-0">
                    <h4 class="text-uppercase mb-4" style="color:white;">Reseaux sociaux</h4>
                    <a class="btn btn-outline-light btn-social mx-1" href="#!"><i class="i-Facebook"></i></a>
                </div>
                <!-- Footer About Text-->
                <div class="col-lg-4">
                    <h4 class="text-uppercase mb-4" style="color:white;">Apropos de Pledika</h4>
                    <p class="lead mb-0">Plateforme pour la gestion des etablissements scolaires <a style="color:white;" href="https://pledika.com">Pledika</a>.
                    </p>
                </div>
            </div>
        </div>
    </footer>
    <!-- Copyright Section-->
    <div class="copyright py-4 text-center text-white">
        <div class="container"><small>Copyright © {{etab?.name}} 2020</small></div>
    </div>
    <!-- Scroll to Top Button (Only visible on small and extra-small screen sizes)-->
    <div class="scroll-to-top d-lg-none position-fixed">
        <a class="js-scroll-trigger d-block text-center text-white rounded" href="#page-top"><i class="fa fa-chevron-up"></i></a>
    </div>

</div>