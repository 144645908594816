<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <!-- end of row-->
        <div class="col-md-12 mb-2">
            <h4 class="mb-3  text-center"> Tableau </h4>
            <h5 class="text-danger text-center">
                <a class="text-danger" routerLink="/app/management/{{ID}}">Retournez a la page d'acceuil</a> </h5>
        </div>

        <div class="col-md-12">

        </div>


        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->