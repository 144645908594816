<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="row ">
            <div class="col-md-12">
                <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                    {{response.message}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                   </button>
                </div>
                <div class="col-md-12" style="margin-bottom:2%;" *ngIf="!es">

                    <div class="card-body">
                        <b class="card-title mb-3">Ajouter espace</b>
                    </div>

                    <div class="row col-md-12">
                        <div class="form-group col-md-2">
                            <label for="recipient-name-1" class="col-form-label">Code</label>
                            <input type="text" class="form-control" [(ngModel)]="s.code" />
                        </div>
                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Salle</label>
                            <input type="text" class="form-control" [(ngModel)]="s.name" />
                        </div>
                        <div class="form-group col-md-2">
                            <label for="recipient-name-1" class="col-form-label">Type</label>
                            <select class="form-control" [(ngModel)]="s.type_salle">
                             <option value="Laboratoire">Laboratoire</option>
                             <option value="Salle" Selected>Salle</option>
                             <option value="Terrian">Terrain</option>
                             <option value="Auditorium">Auditorium</option>
                             <option value="Amphitheatre">Amphitheatre</option>
                           </select>
                        </div>
                        <div class="form-group col-md-1">
                            <label for="recipient-name-1" class="col-form-label">De</label>
                            <input type="number" class="form-control" [(ngModel)]="s.index" />
                        </div>
                        <div class="form-group col-md-1">
                            <label for="recipient-name-1" class="col-form-label">A</label>
                            <input type="number" class="form-control" [(ngModel)]="s.end" />
                        </div>
                        <div class="form-group col-md-2" style="margin-top:1.8%;">
                            <button class="btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="addNewSalle()" [disabled]='loading || s.code === "" || s.name === ""  || s.type_salle === "" '>
                             <span *ngIf='loading'  class="spinner-border spinner-border-sm mr-1"></span>
                               Ajouter
                         </button>
                        </div>
                    </div>

                </div>

                <div class="card " style="margin-bottom:2%;" *ngIf="es">

                    <div class="card-body">
                        <b class="card-title mb-3">Modifier espace</b>
                        <button type="button" class="close" (click)="closeEditSalle()">
                     <span aria-hidden="true">&times;</span>
                   </button>
                    </div>

                    <div class="row col-md-12">
                        <div class="form-group col-md-2">
                            <label for="recipient-name-1" class="col-form-label">Code</label>
                            <input [disabled]="true" type="text" class="form-control" [(ngModel)]="es.code" />
                        </div>
                        <div class="form-group col-md-4">
                            <label for="recipient-name-1" class="col-form-label">Salle</label>
                            <input type="text" class="form-control" [(ngModel)]="es.name" />
                        </div>
                        <div class="form-group col-md-4">
                            <label for="recipient-name-1" class="col-form-label">Type</label>
                            <select class="form-control" [(ngModel)]="es.type_salle">
                             <option value="Laboratoire">Laboratoire</option>
                             <option value="Salle" Selected>Salle</option>
                             <option value="Terrian">Terrain</option>
                             <option value="Auditorium">Auditorium</option>
                             <option value="Amphitheatre">Amphitheatre</option>
                           </select>
                        </div>
                        <div class="form-group col-md-2" style="margin-top:3.5%;">
                            <button class="btn btn-primary  btn-rounded mt-3" style="float:right;" (click)="editSubmitSalle()" [disabled]='loading || es.code === "" || es.name === ""  || es.type_salle === "" '>
                             <span *ngIf='loading'  class="spinner-border spinner-border-sm mr-1"></span>
                               Modifier
                         </button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-md-12">
                <div *ngIf="!salles" class="text-center">
                    <div class="spinner-bubble spinner-bubble-warning m-5"></div>
                </div>
                <div class="col-md-12" *ngIf="salles">
                    <b class="card-title mb-3">La liste des espaces</b>
                    <div class="col-md-12">
                        <div class="col-md-2" *ngFor="let obj of salles; let i = index" style="display:inline-block;">
                            <div class="card card-icon mb-4">
                                <i class=""></i>
                                <div class="card-body text-center">
                                    <p class="text-muted mt-2 mb-2"> <b>{{obj.code}}-{{ obj.type_salle}}
                                        {{ obj.name}}</b>
                                    </p>
                                    <div class="col-md-12">
                                        <a (click)="editSalle(obj,i)" class="text-success mr-2">
                                            <i class="nav-icon i-Pen-2 font-weight-bold"></i>
                                        </a>
                                        <a (click)="delSalle(obj,i)" class="text-danger mr-2">
                                            <i class="nav-icon i-Close-Window font-weight-bold"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <!-- end of row-->
        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
</div>