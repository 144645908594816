<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="row ">
            <div class="separator-breadcrumb border-top "></div>
            <div class=" col-md-12">
                <h3 class="mb-2 text-center">Statistique Moyenne <br/><b class="text-success">{{frag?.code}} </b> </h3>
            </div>
            <div class=" col-md-8 ">
                <div class="card text-left">
                    <div class="card-body">

                        <div class="col-md-12">
                            <div class="accordion" id="accordionExample">
                                <div class="card" *ngFor="let item of stats; let ci= index">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" attr.data-target="#collapseOne_{{item.id}}" aria-expanded="true" aria-controls="collapseOne">
                                            {{item?.period?.split("-")[3]}}
                                    </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne_{{item.id}}" class="collapse show" [ngClass]="{'show' : ci==0}" aria-labelledby="headingOne" data-parent="#accordionExample">
                                        <div class="card-body">

                                            <table id="Sheet_A_{{item.id}}" class="display table table-striped table-bordered" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Garçon</th>
                                                        <th>Fille</th>
                                                        <th>Total</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>

                                                        <td> Elève de la classe </td>
                                                        <td>{{item.total_g}}</td>
                                                        <td>{{item.total_f}}</td>
                                                        <td>{{item.total_class}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Elève Present en classe </td>
                                                        <td>{{item.total_ig}}</td>
                                                        <td>{{item.total_if}}</td>
                                                        <td>{{item.total_iclass}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                            <table id="Sheet_B_{{item.id}}" class="display table table-striped table-bordered" style="width:100%">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Reussite</th>
                                                        <th>Echec</th>

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td> Elève de la classe </td>
                                                        <td>{{item.total_pass}}</td>
                                                        <td>{{item.total_echec}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Garçon </td>
                                                        <td>{{item.total_g_pass}}</td>
                                                        <td>{{item.total_g_echec}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td> Fille </td>
                                                        <td>{{item.total_f_pass}}</td>
                                                        <td>{{item.total_f_echec}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class=" col-md-4 ">
                <div class=" col-md-12">
                    <h5 class="mb-2 text-center">Promotions</h5>
                </div>
                <div *ngFor="let o of frags;let i = index" class="card text-center m-2">
                    <a [ngClass]="{'text-success': ID == o.id}" routerLink="/app/statMoys/{{o.id}}">
                       {{o.code.split("-")[0]}}
                    </a>
                </div>
            </div>
        </div>
        <!-- Footer Start -->
        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
</div>
