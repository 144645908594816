<div class="app-admin-wrap">

    <app-navbar></app-navbar>
    <app-side></app-side>
    <!--=============== Left side End ================-->
    <!-- ============ Body content start ============= -->
    <div class="main-content-wrap sidenav-open d-flex flex-column">
        <app-breadcrumb></app-breadcrumb>
        <div class="col-md-12 mb-4">
            <div class="col-md-12 mb-2">
                <h3 class="mb-3  text-center" *ngIf="ID==undefined"> Modifier profil</h3>
                <h5 class="text-danger text-center" *ngIf="ID==undefined"> <a class="text-danger" routerLink="/app/promoDetails/{{ID}}">Retournez</a> </h5>
            </div>
            <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                {{response.message}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span
       aria-hidden="true">&times;</span></button>
            </div>
            <div class="col-md-12" *ngIf="promo && !over">

                <div class="col-md-12 p-4">
                    <h3 class="mb-3  text-center">Voulez-vous vraiment supprimer cette promotion ? </h3>
                    <h4 class="mb-3  text-center">{{promo?.code}}</h4>
                    <div style=" width:auto; margin-left:25%;">
                        <button (click)="delPromo()" [disabled]="loading" class=" col-md-4 btn-sm  btn  btn-success mr-8  mt-3">
          Oui </button>
                        <a routerLink="/app/promoDetails/{{ID}}" class=" col-md-4 btn btn-sm ml-4 btn-danger  mt-3 ">
                          Non </a>
                    </div>
                </div>
            </div>
            <div class="col-md-12 text-center" *ngIf="over">
                <h3 class="mb-3  text-center">Cette promotion n'existe pas ? </h3>
                <h4 class="mb-3  text-center">{{promo?.code}}</h4>
                <a routerLink="/app/home" class=" col-md-4 btn btn-sm ml-4 btn-danger text-center  mt-3 ">
                    Retournez a l'acceuil </a>
            </div>

            <!-- end of col-->
        </div>
        <!-- end of row-->

        <app-footer></app-footer>
        <!-- fotter end -->
    </div>
    <!-- ============ Body content End ============= -->
</div>
<!--=============== End app-admin-wrap ================-->

<!-- ============ Search UI Start ============= -->
<app-search></app-search>
<!-- ============ Search UI End ============= -->