<div class="modal fade" id="{{idModal}}" tabindex="-1" role="dialog" data-backdrop="false" attr.aria-labelledby="{{idModal}}" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close"><i class="nav-icon i-refresh"></i></button>
                <h5 class="modal-title" id="verifyModalContent2_title">Admission - {{classe}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">

                <div *ngIf="response.active" class="alert alert-card alert-{{response.state}} text-center" role="alert">
                    {{response.message}}
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <form [formGroup]="addForm" (ngSubmit)="add()">
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Prénom</label>
                            <input type="text" id="pnom-{{idModal}}" formControlName="pnom" [ngClass]="{ 'is-invalid': this.submitted &&  f.pnom.errors }" class="form-control">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Nom</label>
                            <input type="text" id="nom-{{idModal}}" formControlName="lastName" [ngClass]="{ 'is-invalid': this.submitted &&  f.lastName.errors }" class="form-control">
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Sexe</label>
                            <select id="sexe-{{idModal}}" class="form-control" formControlName="sexe" [ngClass]="{ 'is-invalid':this.submitted && f.sexe.errors }">
                           <option value="M">Masculin</option>
                           <option value="F">Féminin</option>
                         </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Date de naissane</label>
                            <input id="date-{{idModal}}" type="date" [ngClass]="{ 'is-invalid': this.submitted && f.date_de_naiss.errors }" formControlName="date_de_naiss" class="form-control" id="recipient-name-1">
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1">Email</label>
                            <input type="text" id="email-{{idModal}}" [ngClass]="{ 'is-invalid': this.submitted &&  f.username.errors }" class="form-control" formControlName="username" id="recipient-name-1">
                        </div>
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1">Lieu de naissance</label>
                            <select id="lieu-{{idModal}}" formControlName="lieu" class="form-control" [ngClass]="{ 'is-invalid': this.submitted && f.lieu.errors }">
                        <option [value]="vil.id" *ngFor="let vil of villes">{{vil.name}}
                        </option>
                      </select>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Nom Assurance</label>
                            <input type="text" id="ns-{{idModal}}" formControlName="nom_ass" [ngClass]="{ 'is-invalid': this.submitted &&  f.nom_ass.errors }" class="form-control">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Téléphone Assurance</label>
                            <input type="text" id="ps-{{idModal}}" formControlName="phone_ass" [ngClass]="{ 'is-invalid': this.submitted &&  f.phone_ass.errors }" class="form-control">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Adresse Assurance</label>
                            <input type="text" id="as-{{idModal}}" formControlName="adresse_ass" [ngClass]="{ 'is-invalid': this.submitted &&  f.adresse_ass.errors }" class="form-control">
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Derniere Etablissement</label>
                            <input type="text" id="last_etab-{{idModal}}" formControlName="last_etab" [ngClass]="{ 'is-invalid': this.submitted &&  f.last_etab.errors }" class="form-control">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Année depart</label>
                            <input type="text" id="last_year-{{idModal}}" formControlName="last_year" [ngClass]="{ 'is-invalid': this.submitted &&  f.last_year.errors }" class="form-control">
                        </div>

                        <div class="form-group col-md-3">
                            <label for="recipient-name-1" class="col-form-label">Moyenne de sortie</label>
                            <input type="text" id="last_moyen-{{idModal}}" formControlName="last_moyen" [ngClass]="{ 'is-invalid': this.submitted &&  f.last_moyen.errors }" class="form-control">
                        </div>
                    </div>





                    <div class="row">
                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">A refaire</label>
                            <select id="isDouble-{{idModal}}" formControlName="is_double" class="form-control" [ngClass]="{ 'is-invalid':this.submitted && f.is_double.errors }">
                              <option [value]="true">Oui</option>
                              <option [value]="false">Non</option>
                            </select>
                        </div>


                        <div class="form-group col-md-6">
                            <label for="recipient-name-1" class="col-form-label">Référence</label>
                            <input id="ref-{{idModal}}" type="text" [ngClass]="{ 'is-invalid': this.submitted &&  f.ref.errors }" class="form-control" formControlName="ref" id="recipient-name-1">
                        </div>
                    </div>


                    <button style="float:right;" type="submit" id="btn-{{idModal}}" [disabled]="loading" class="btn btn-primary  btn-rounded mt-3">
                     <span *ngIf="loading"   class="spinner-border spinner-border-sm mr-1"></span>
                      Suivant
                    </button>

                    <a id="btn-reset-{{idModal}}" type="button" class="btn btn-warning btn-rounded mt-3 " (click)="reset()">
                     Réinitialiser
                   </a>

                </form>
            </div>

        </div>
    </div>
</div>